<template>
  <v-container v-if="vueCidades">
    <PageToolbar :title="$tc('pages.cidade', 2)" icon="location_on" dark />

    <ListaCidades class="mt-4" :key="componentKey" />

    <v-dialog v-model="dialog" max-width="290">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">{{
          $tc("global.nova") + " " + $tc("pages.cidade")
        }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="cidade.nome"
            :label="$tc('global.nome')"
            dense
            hide-details
          ></v-text-field>
          <v-text-field
            v-model="cidade.departamento"
            :label="$tc('global.estado')"
            class="mt-4"
            dense
            hide-details
          ></v-text-field>
          <v-text-field
            v-model="cidade.pais"
            :label="$tc('global.pais')"
            class="mt-4"
            dense
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="anchors" text @click="dialog = false">{{
            $tc("botoes.cancelar")
          }}</v-btn>
          <v-btn color="anchors" text @click="createCidade">{{
            $tc("botoes.criar")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="vueAddCidade"
      @click="dialog = true"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { postCidade } from "@/services/api/cidades.api.js";

import { mapGetters } from "vuex";

export default {
  name: "Cidades",
  components: {
    ListaCidades: () => import("./components/ListaCidades.vue"),
  },
  data: () => ({
    componentKey: 0,
    cidade: {},
    dialog: false,
    loading: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueCidades() {
      return this.getAccess("vueCidades", "menu");
    },
    vueAddCidade() {
      return this.getAccess("vueCidade", "adicionar");
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    createCidade() {
      this.loading = true;
      postCidade(this.cidade)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.cidade = {};
            this.forceRerender();
            this.dialog = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style></style>
